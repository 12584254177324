// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  height: 64px;
}

div.avatar {
  flex: 0 0 64px;
}
div.avatar img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

div.details {
  padding-left: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImF2YXRhci1jYXJkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLFlBQUE7QUFDRjs7QUFFQTtFQUNFLGNBQUE7QUFDRjtBQUFFO0VBQ0UsV0FBQTtFQUNBLFlBQUE7RUFDQSxrQkFBQTtBQUVKOztBQUVBO0VBQ0Usa0JBQUE7RUFDQSxPQUFBO0VBQ0EsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsdUJBQUE7RUFDQSw2QkFBQTtBQUNGIiwiZmlsZSI6ImF2YXRhci1jYXJkLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICBkaXNwbGF5OiBmbGV4O1xuICBoZWlnaHQ6IDY0cHg7XG59XG5cbmRpdi5hdmF0YXIge1xuICBmbGV4OiAwIDAgNjRweDtcbiAgaW1nIHtcbiAgICB3aWR0aDogNjRweDtcbiAgICBoZWlnaHQ6IDY0cHg7XG4gICAgYm9yZGVyLXJhZGl1czogNTAlO1xuICB9XG59XG5cbmRpdi5kZXRhaWxzIHtcbiAgcGFkZGluZy1sZWZ0OiAxMnB4O1xuICBmbGV4OiAxO1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBhbGlnbi1pdGVtczogZmxleC1zdGFydDtcbiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1hcm91bmQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./projects/sp-shared-lib/src/lib/shared/avatar-card/avatar-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAEJ;;AAEA;EACE,kBAAA;EACA,OAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,6BAAA;AACF;AACA,w3BAAw3B","sourcesContent":[":host {\n  display: flex;\n  height: 64px;\n}\n\ndiv.avatar {\n  flex: 0 0 64px;\n  img {\n    width: 64px;\n    height: 64px;\n    border-radius: 50%;\n  }\n}\n\ndiv.details {\n  padding-left: 12px;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: space-around;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
