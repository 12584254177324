import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { StorageService } from './storage.service';

export interface AuthResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  roles: string[];
  token_type: string;
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  credentials: AuthResponse;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  /*
 No longer used as we do not authenticate in the onboarding
 */
  //
  // authenticate() {
  //   return this.http.post(`${environment.auth_url}/login`, {
  //     email: 'admin@sharepeople.nl',
  //     password: 'password'
  //   }).pipe(
  //     tap((creds: AuthResponse) => {
  //       this.credentials = creds;
  //       this.storageService.setCredentials(creds);
  //     })
  //   );
  // }
  //
  // getCredentials(): AuthResponse {
  //   if (this.credentials) {
  //     return this.credentials;
  //   } else {
  //     return this.storageService.getCredentials();
  //   }
  // }


}
