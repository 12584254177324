import { Component, OnInit } from '@angular/core';
import {FooterButtonType} from '../../shared-module/onboard-page-content-wrapper/onboard-page-content-wrapper.component';

@Component({
  selector: 'sp-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent {

  footerConfig = {btnType: FooterButtonType.NAVIGATION, navigateTo: '../income', footerBtnText: 'Bekijk de kosten'};

  constructor() { }
}
