// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .main {
  padding: 0 0 0 0 !important;
}

#exit-button {
  background-color: white;
  color: #1f2129;
}

b {
  font-weight: bold;
  font-family: "Mukta Mahee", sans-serif;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlamVjdGVkLXNjcmVlbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDJCQUFBO0FBQ0Y7O0FBRUE7RUFDRSx1QkFBQTtFQUNBLGNBQUE7QUFDRjs7QUFDQTtFQUNFLGlCQUFBO0VBQ0Esc0NBQUE7QUFFRiIsImZpbGUiOiJyZWplY3RlZC1zY3JlZW4uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdDo6bmctZGVlcCAubWFpbiB7XG4gIHBhZGRpbmc6IDAgMCAwIDAgIWltcG9ydGFudDtcbn1cblxuI2V4aXQtYnV0dG9uIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogd2hpdGU7XG4gIGNvbG9yOiAjMWYyMTI5O1xufVxuYiB7XG4gIGZvbnQtd2VpZ2h0OmJvbGQ7XG4gIGZvbnQtZmFtaWx5OiBcIk11a3RhIE1haGVlXCIsIHNhbnMtc2VyaWY7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./projects/onboarding/src/app/shared-module/pages/rejected-screen/rejected-screen.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;;AAEA;EACE,uBAAA;EACA,cAAA;AACF;;AACA;EACE,iBAAA;EACA,sCAAA;AAEF;AACA,onBAAonB","sourcesContent":[":host::ng-deep .main {\n  padding: 0 0 0 0 !important;\n}\n\n#exit-button {\n  background-color: white;\n  color: #1f2129;\n}\nb {\n  font-weight:bold;\n  font-family: \"Mukta Mahee\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
