import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SpLongComponent} from './sp-long.component';
import {ErrorPageComponent} from '../shared-module/pages/error-page/error-page.component';
import {IntroComponent} from './intro/intro.component';
import {KnowledgeTestComponent} from '../shared-module/pages/knowledge-test/knowledge-test.component';
import {PaymentPlanConfirmationComponent} from './payment-plan-confirmation/payment-plan-confirmation.component';
import {IncomeComponent} from '../shared-module/pages/income/income.component';
import {SPLongPromiseComponent} from '../shared-module/pages/sp-long-promise/sp-long-promise.component';
import {SuccessComponent} from './success/success.component';
import {RejectedScreenComponent} from '../shared-module/pages/rejected-screen/rejected-screen.component';
import {GoodToKnowComponent} from './good-to-know/good-to-know.component';
import {PaticipationComponent} from '../shared-module/pages/paticipation/paticipation.component';
import {CrowdsuranceComponent} from './crowdsurance/crowdsurance.component';

const routes: Routes = [
  {
    path: '',
    component: SpLongComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'intro'},
      {path: 'intro', component: IntroComponent},
      {path: 'income', component: IncomeComponent},
      {path: 'knowledge-test', component: KnowledgeTestComponent},
      {path: 'good-to-know', component: GoodToKnowComponent},
      {path: 'crowdsurance', component: CrowdsuranceComponent},
      {path: 'participation', component: PaticipationComponent},
      {path: 'promise', component: SPLongPromiseComponent},
      {path: 'payment-plan', component: PaymentPlanConfirmationComponent},
      {path: 'success', component: SuccessComponent},
      {path: 'rejected', component: RejectedScreenComponent},
      {path: 'error-page', component: ErrorPageComponent},
      {path: '**', redirectTo: 'intro'},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SpLongRoutingModule { }
