import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/sp-prospects/start'},
  {
    path: 'sp-prospects',
    loadChildren: () => {return import('./sp-prospects-module/sp-prospects.module').then(m => {return m.SpProspectsModule;});}
  },
  {
    path: 'sp-long',
    loadChildren: () => {return import('./sp-long-module/sp-long.module').then(m => {return m.SpLongModule;});},
  },
  {
    path: '',
    loadChildren: () => {return import('./onboarding-module/onboarding.module').then(m => {return m.OnboardingModule;});},
  },
  {
    path: 'registration',
    loadChildren: () => {return import('../../../my-portal/src/app/registration-module/registration.module').then(m => {return m.RegistrationModule;});},
  },
  {
    path: 'my-area',
    loadChildren: () => {return import('../../../my-portal/src/app/my-area-module/my-area.module').then(m => {return m.MyAreaModule;});},
  },
  {path: '**', redirectTo: '/sp-prospects/start'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    // enableTracing: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
