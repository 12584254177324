import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CustomValidators} from '../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {IUserPaymentConfirmation, PaymentPeriod, PaymentType, SpLongInvitationCode} from '../models/sp-long.models';
import {SpLongService} from '../services/sp-long-service/sp-long.service';
import {FooterButtonType} from '../../shared-module/onboard-page-content-wrapper/onboard-page-content-wrapper.component';


@Component({
  selector: 'sp-payment-plan-confirmation',
  templateUrl: './payment-plan-confirmation.component.html',
  styleUrls: ['./payment-plan-confirmation.component.scss']
})
export class PaymentPlanConfirmationComponent implements OnInit{


  paymentConfirmationForm: UntypedFormGroup = this.formBuilder.group({});
  amount: number;
  amountWithVat: number;
  paymentPeriod: PaymentPeriod;
  paymentType: PaymentType;
  paymentPeriodPhrase: string;
  invitationCode: string;

  footerConfig = {btnType: FooterButtonType.CLICKEVENT, footerBtnText: 'Ga verder', outputBtnClickEvent: () => {return this.handleConfirmation();}};

  constructor(
    private spService: SpLongService,
    private formBuilder: UntypedFormBuilder,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.invitationCode = localStorage.getItem(SpLongInvitationCode.SPLONGINVITATIONCODE);
    this.buildForm();
    this.getPaymentTerms();
  }

  getPaymentTerms() {
    this.spService.getPaymentTerms(this.invitationCode).subscribe(res => {
      this.paymentPeriod = res.paymentPeriod;
      this.paymentType = res.paymentType;
      if (this.paymentPeriod === PaymentPeriod.MONTHLY) {
        this.paymentPeriod = PaymentPeriod.MONTHLYINDUTCH;
        this.amount = res.monthlyAmount;
        this.amountWithVat = res.monthlyAmountVAT;
        this.paymentPeriodPhrase = 'elke maand';
      } else {
        this.amount = res.annualAmount;
        this.amountWithVat = res.annualAmountVAT;
        this.paymentPeriod = PaymentPeriod.ANNUALINDUTCH;
        this.paymentPeriodPhrase = 'elk jaar';
      }
    });
  }

  buildForm(): void {
    this.paymentConfirmationForm = this.formBuilder.group({
      confirmed: [null, [Validators.required, CustomValidators.valueIsTrue]]
    });
  }

  handleConfirmation() {
    const acceptPayment = {
      acceptPayment: true
    };
    this.spService.postUserPaymentConfirmation(this.invitationCode, acceptPayment).subscribe((res: IUserPaymentConfirmation) => {
      if (res) {
        this.router.navigate(['sp-long/success']);
      }
    });
  }
}
