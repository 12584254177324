import { Component, OnInit } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {OnboardingService} from '../../onboarding-module/onboarding.service';

@Component({
  selector: 'sp-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  memberCode$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  footerConfig = {btnType: null, footerBtnText: null, outputBtnClickEvent: null};

  constructor(
    private onboardingService: OnboardingService
  ) { }

  ngOnInit(): void {
    this.onboardingService.getMemberCode().subscribe( (memberCode: string) => {
      this.memberCode$.next(memberCode);
      console.log(memberCode);
    });
  }

  goToActure() {
    window.location.href = `https://wga.acture.nl/health-declaration?membercode=${this.memberCode$.value}`;
  }

}
