import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FooterButtonType} from '../../shared-module/onboard-page-content-wrapper/onboard-page-content-wrapper.component';

@Component({
  selector: 'sp-good-to-know',
  templateUrl: './good-to-know.component.html',
  styleUrls: ['./good-to-know.component.scss']
})
export class GoodToKnowComponent implements OnInit {

  footerConfig = {btnType: FooterButtonType.CLICKEVENT, footerBtnText: 'Ik begrijp het', outputBtnClickEvent: () => {return this.submit();}};

  constructor(private router: Router) {}

  ngOnInit(): void {}

  submit() {
    this.router.navigate(['sp-long/crowdsurance']);
  }
}
