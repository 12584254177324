import { NgModule } from '@angular/core';
import { OnboardingRoutingModule } from './onboarding-routing.module';
import { OnboardingComponent } from './onboarding.component';
import { SharedModule } from '../shared-module/shared.module';
import { OnboardingRestService } from './onboarding.rest.service';
import { BreakALegComponent } from './break-a-leg/break-a-leg.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ProcessingPaymentComponent } from '../shared-module/pages/processing-payment/processing-payment.component';
import {MembershipPaymentComponent} from './membership-payment/membership-payment.component';
import { MgmInvitedByComponent } from './break-a-leg/mgm-invited-by/mgm-invited-by.component';
import {SpSharedLibModule} from 'sp-shared-lib';


@NgModule({
  imports: [
    SpSharedLibModule,
    SharedModule,
    OnboardingRoutingModule
  ],
  exports: [
    SpSharedLibModule,
    SharedModule
  ],
  declarations: [
    OnboardingComponent,
    MembershipPaymentComponent,
    BreakALegComponent,
    ConfirmEmailComponent,
    MgmInvitedByComponent
  ],
  providers: [
    OnboardingRestService
  ]
})
export class OnboardingModule { }
