import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SpLongRoutingModule} from './sp-long-routing.module';
import {SpLongComponent} from './sp-long.component';
import {SharedModule} from '../shared-module/shared.module';
import {IntroComponent} from './intro/intro.component';
import {PaymentPlanConfirmationComponent} from './payment-plan-confirmation/payment-plan-confirmation.component';
import {SpLongRestService} from './services/rest-service/sp-long-rest.service';
import {CookieService} from 'ngx-cookie-service';
import {SuccessComponent} from './success/success.component';
import {GoodToKnowComponent} from './good-to-know/good-to-know.component';
import {RouterModule} from '@angular/router';
import {CrowdsuranceComponent} from './crowdsurance/crowdsurance.component';


@NgModule({
  declarations: [
    SpLongComponent,
    IntroComponent,
    PaymentPlanConfirmationComponent,
    GoodToKnowComponent,
    CrowdsuranceComponent,
    SuccessComponent
  ],
  imports: [
    CommonModule,
    SpLongRoutingModule,
    RouterModule,
    SharedModule
  ],
  providers: [
    SpLongRestService,
    CookieService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SpLongModule { }
