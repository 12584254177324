import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChoosePaymentComponent } from '../shared-module/pages/choose-payment/choose-payment.component';
import { OnboardingComponent } from './onboarding.component';
import { NameAndContactComponent } from '../shared-module/pages/name-and-contact/name-and-contact.component';
import { AddressComponent } from '../shared-module/pages/address/address.component';
import { CompanyComponent } from '../shared-module/pages/company/company.component';
import { PromiseComponent } from '../shared-module/pages/promise/promise.component';
import { ConfirmationComponent } from '../shared-module/pages/confirmation/confirmation.component';
import { MembershipPaymentComponent } from './membership-payment/membership-payment.component';
import { ErrorPageComponent } from '../shared-module/pages/error-page/error-page.component';
import { BreakALegComponent } from './break-a-leg/break-a-leg.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import {ProcessingPaymentComponent} from '../shared-module/pages/processing-payment/processing-payment.component';
import {StartComponent} from '../shared-module/pages/start/start.component';

const routes: Routes = [
  {
    path: '',
    component: OnboardingComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'start'},
      {path: 'start', component: StartComponent},
      {path: 'choose-payment', component: ChoosePaymentComponent},
      {path: 'name-and-contact', component: NameAndContactComponent},
      {path: 'address', component: AddressComponent},
      {path: 'company', component: CompanyComponent},
      {path: 'promise', component: PromiseComponent},
      {path: 'confirmation', component: ConfirmationComponent},
      {path: 'processing-payment', component: ProcessingPaymentComponent},
      {path: 'membership-payment', component: MembershipPaymentComponent},
      {path: 'error-page', component: ErrorPageComponent},
      {path: 'break-a-leg', component: BreakALegComponent},
      {path: 'confirm-email', component: ConfirmEmailComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardingRoutingModule { }
