import {Component, OnDestroy, OnInit} from '@angular/core';
import {ThemeService} from '../../../sp-shared-lib/src/lib/shared/services/theme.service';
import {GoogleAnalyticsService} from './core-module/services/google-analytics.service';
import {OnboardingTypeService} from './core-module/services/onboarding-type.service';

@Component({
  selector: 'sp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private ga: GoogleAnalyticsService,
    private themeService: ThemeService,
    private onboardTypeService: OnboardingTypeService,
  ) {}

  ngOnInit() {
    this.themeService.init();
    this.themeService._runningProject.next('onboarding');
    this.onboardTypeService.listenToRouteChanges();
  }

  ngOnDestroy() {
    this.themeService._runningProject.next(null);
    this.onboardTypeService.removeOnboardType();
  }

}
