// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.message-container {
  width: 250px;
  margin: 48px auto;
}

p {
  text-align: center;
}

sp-loading-spinner {
  margin-top: 45px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2Nlc3NpbmctcGF5bWVudC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLFlBQUE7RUFDQSxpQkFBQTtBQUFGOztBQUVBO0VBQ0Usa0JBQUE7QUFDRjs7QUFFQTtFQUNFLGdCQUFBO0FBQ0YiLCJmaWxlIjoicHJvY2Vzc2luZy1wYXltZW50LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiXG5kaXYubWVzc2FnZS1jb250YWluZXIge1xuICB3aWR0aDogMjUwcHg7XG4gIG1hcmdpbjogNDhweCBhdXRvO1xufVxucCB7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbn1cblxuc3AtbG9hZGluZy1zcGlubmVyIHtcbiAgbWFyZ2luLXRvcDogNDVweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/onboarding/src/app/shared-module/pages/processing-payment/processing-payment.component.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,iBAAA;AAAF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;AACA,wiBAAwiB","sourcesContent":["\ndiv.message-container {\n  width: 250px;\n  margin: 48px auto;\n}\np {\n  text-align: center;\n}\n\nsp-loading-spinner {\n  margin-top: 45px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
