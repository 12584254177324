import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SentryErrorHandler} from './core-module/sentry-error-handler';
import {OnboardingModule} from './onboarding-module/onboarding.module';
import {CoreModule} from './core-module/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import {SharedModule} from './shared-module/shared.module';
import {SpLongModule} from './sp-long-module/sp-long.module';
import {CookieService} from 'ngx-cookie-service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    OnboardingModule,
    SpLongModule,
    SharedModule,
    // This SW is disabled. also disabled in the index.html (webmanifest)
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false })
  ],
  providers: [
    CookieService,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: LOCALE_ID,
      useValue: 'nl'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
