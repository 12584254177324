import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {CustomValidators} from '../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {OnboardingService} from '../onboarding.service';
import {catchError} from 'rxjs/operators';
import {EMPTY, throwError} from 'rxjs';
import {FooterButtonType} from '../../shared-module/onboard-page-content-wrapper/onboard-page-content-wrapper.component';

@Component({
  selector: 'sp-membership-payment',
  templateUrl: './membership-payment.component.html',
  styleUrls: ['./membership-payment.component.scss']
})

export class MembershipPaymentComponent implements OnInit {

  membershipForm: UntypedFormGroup;
  paymentRequest;

  footerConfig = {btnType: FooterButtonType.CLICKEVENT, footerBtnText: 'Afronden', outputBtnClickEvent: () => {return this.submit();}};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private onboardingService: OnboardingService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createForm();
    this.paymentRequest = this.getPaymentRequest();
  }

  createForm() {
    this.membershipForm = this.formBuilder.group({
      month: [null, [Validators.required]],
      iban: [null],
      termsAccepted: [false, [Validators.required, CustomValidators.valueIsTrue]]
    });
  }

  getPaymentRequest() {
    return this.onboardingService.retrievePaymentRequest();
  }

  submit() {
    if (this.membershipForm.valid) {
      this.onboardingService.setMembershipPayment(this.membershipForm.value)
        .pipe(
          catchError((origErr) => {
            if (origErr.status === 422) {
              this.membershipForm.get('iban').setErrors({
                invalidIban: true
              });
              return EMPTY;
            } else {
              return throwError(origErr);
            }
          })
        )
        .subscribe(
          (resp: any) => {
            if (resp.status && resp.status === 'OK') {
              this.router.navigate(['/break-a-leg']);
            }
          }
        );
    } else {
      this.membershipForm.markAllAsTouched();
    }
  }
}
