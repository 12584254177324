import { Component, OnInit } from '@angular/core';
import {ThemeService} from '../../../../sp-shared-lib/src/lib/shared/services/theme.service';
import {Observable} from 'rxjs';
import {OnboardingService} from '../onboarding-module/onboarding.service';

@Component({
  selector: 'sp-sp-long',
  templateUrl: './sp-long.component.html',
  styleUrls: ['./sp-long.component.scss', '../../../../global-scss/responsive-wrapper.scss']
})
export class SpLongComponent implements OnInit {

  spLongInvitationCode: string;
  spLogo$: Observable<any>;

  constructor(
    private themeService: ThemeService,
    private onboardingService: OnboardingService
  ) { }

  ngOnInit(): void {
    this.spLogo$ = this.themeService.getLogo();
    this.onboardingService.checkQueries(this.spLongInvitationCode);
  }

  revertStateCounter() {
    this.onboardingService.revertAllLocalDataAndRestart();
  }



}
