import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {FooterButtonType} from '../../shared-module/onboard-page-content-wrapper/onboard-page-content-wrapper.component';

@Component({
  selector: 'sp-crowdsurance',
  templateUrl: './crowdsurance.component.html',
  styleUrls: ['./crowdsurance.component.scss']
})
export class CrowdsuranceComponent {

  footerConfig = {
    btnType: FooterButtonType.CLICKEVENT, footerBtnText: 'Ik begrijp het', outputBtnClickEvent: () => {
      return this.submit();
    }
  };

  constructor(private router: Router) {
  }

  submit() {
    this.router.navigate(['sp-long/promise']);
  }
}
