import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {ThemeService} from '../../../../../sp-shared-lib/src/lib/shared/services/theme.service';
import {OnboardingService} from "../onboarding.service";
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'sp-break-a-leg',
  templateUrl: './break-a-leg.component.html',
  styleUrls: ['./break-a-leg.component.scss']
})
export class BreakALegComponent implements OnInit {
  private redirectSubscription: Subscription | null;
  constructor(
    private themeService: ThemeService,
    private onboardingService: OnboardingService
  ) { }

  redirectUrl: string;
  displayMgmInvitedBy: boolean;

  ngOnInit() {
    this.themeService.getCurrentTheme()
      .subscribe(currentTheme => {
        this.displayMgmInvitedBy = false;
        this.redirectUrl = currentTheme.redirectUrl;
      });

    this.onboardingService.getDiscountCodeRedirectUrl()?.subscribe((redirectUrl: string) => {
      this.redirectUrl = redirectUrl;
      if (this.redirectUrl) {
        this.redirectSubscription = interval(10000)
          .subscribe((val) => this.redirect());
      }
    });
  }

  redirect() {
    window.location.href = this.redirectUrl;
  }

  gotoMyProfile() {
    window.location.href = `${environment.portal_url}/`;
  }

}
