// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const BASE_URL = 'https://vps3189.geckoapps.nl/server';
export const environment = {
  production: false,
  api_url: BASE_URL + '/api/v1',
  auth_url: BASE_URL + '/api',
  portal_url: BASE_URL + '/portal',
  // TODO consider moving the e2e configs to some place else since it's not directly related to the application
  e2e_admin_email: 'sharepeople@geckolabs.nl',
  e2e_bo_pw: 'Ce5yu];!q*>,',
  e2e_mail_client_pw: '-[yriw_MnMSF',
  e2e_new_onboarded_acc_pw: '|Gi#cD<zVEoN',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
