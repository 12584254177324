import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {AuthService} from '../core-module/services/auth.service';
import {OnboardingService} from './onboarding.service';
import {ThemeService} from '../../../../sp-shared-lib/src/lib/shared/services/theme.service';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {OnboardingRestService} from './onboarding.rest.service';

@Component({
  selector: 'sp-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss', '../../../../global-scss/responsive-wrapper.scss']
})
export class OnboardingComponent implements OnInit {
  logo: Observable<string>;
  alt: Observable<string>;
  favicon: Observable<string>;
  media: Observable<any>;

  constructor(
    private authService: AuthService,
    private onboardingService: OnboardingService,
    private themeService: ThemeService,
    private onboardingRestService: OnboardingRestService
  ) {
  }

  ngOnInit() {
    if (environment.production && this.routeIsNotMollieCallback()) {
      this.onboardingService.returnUserToStartPage();
    }
    this.media = this.themeService.getLogo();
  }

  routeIsNotMollieCallback() {
    const fragment = window.location.href;
    console.log('fragment: ', fragment);

    try {
      if (environment.production) {
        this.onboardingRestService.postToLoggingEndpoint({landingUrl: fragment}).subscribe();
      }
    } catch (e) {
      console.error('Something went wrong while trying to call the logging endpoint with the landingUrl: ', e);
    }

    return !fragment.includes('processing');
  }

  revertStateCounter() {
    this.onboardingService.revertAllLocalDataAndRestart();
  }
}
