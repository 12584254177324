// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `b {
  font-weight: bold;
  font-family: "Mukta Mahee", sans-serif;
}

ul li {
  margin-top: 4px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNyb3dkc3VyYW5jZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGlCQUFBO0VBQ0Esc0NBQUE7QUFDRjs7QUFHQTtFQUNFLGVBQUE7QUFBRiIsImZpbGUiOiJjcm93ZHN1cmFuY2UuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJiIHtcbiAgZm9udC13ZWlnaHQ6Ym9sZDtcbiAgZm9udC1mYW1pbHk6IFwiTXVrdGEgTWFoZWVcIiwgc2Fucy1zZXJpZjtcbn1cblxuXG51bCBsaSB7XG4gIG1hcmdpbi10b3A6IDRweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/onboarding/src/app/sp-long-module/crowdsurance/crowdsurance.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,sCAAA;AACF;;AAGA;EACE,eAAA;AAAF;AACA,wcAAwc","sourcesContent":["b {\n  font-weight:bold;\n  font-family: \"Mukta Mahee\", sans-serif;\n}\n\n\nul li {\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
