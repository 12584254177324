import { Component, OnInit } from '@angular/core';
import {catchError} from 'rxjs/operators';
import {OnboardingService} from '../../onboarding.service';

@Component({
  selector: 'sp-mgm-invited-by',
  templateUrl: './mgm-invited-by.component.html',
  styleUrls: ['./mgm-invited-by.component.scss']
})
export class MgmInvitedByComponent implements OnInit {
  invitedUser = null;

  constructor(
    private onboardingService: OnboardingService
  ) { }

  ngOnInit() {
  }

  submit(nameOrEmail: string) {
    this.onboardingService.mgmInvitedBy(nameOrEmail)
      .pipe(
        catchError(
          (err => {
            window.alert('Unfortunately there was a error, please retry');
            return err;

          })
        )
      )
      .subscribe(
        (res) => {
          this.invitedUser = nameOrEmail;
        }
      );
  }
}
